<template>
  <div id="averageUserDetail">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>用户管理</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ name: 'averageUserList' }"
        >普通用户列表</el-breadcrumb-item
      >
      <el-breadcrumb-item>详情</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="basicsInfo">
      <h3>基础信息</h3>
      <ul class="info">
        <li class="avatarUrl">
          <span class="label">用户头像：</span>
          <div>
            <el-image
              v-if="info.avatarUrl"
              :src="info.avatarUrl"
              fit="cover"
              :preview-src-list="[info.avatarUrl]"
            ></el-image>
          </div>
        </li>
        <li>
          <span class="label">用户手机号：</span>
          <editable-input :value="info.phoneNum" />
        </li>
        <li>
          <span class="label">用户昵称：</span>
          <editable-input :value="info.nickName" />
        </li>
        <li>
          <span class="label">上次登录方式：</span>
          <editable-input :value="info.lastLoginType" />
        </li>
        <li>
          <span class="label">绑定车辆数：</span>
          <editable-input :value="info.vehicleCount" />
        </li>
        <li>
          <span class="label">钱包金额：</span>
          <editable-input :value="info.accountBalance" />
        </li>
      </ul>
    </div>
    <div class="tab-table">
      <el-tabs v-model="tabsName">
        <el-tab-pane
          label="车辆列表"
          name="averageUserList-detail-vehicleList"
          v-if="tabList.includes('averageUserList-detail-vehicleList')"
        >
          <vehicle-list />
        </el-tab-pane>
        <el-tab-pane
          label="订单列表"
          name="averageUserList-detail-orderList"
          v-if="tabList.includes('averageUserList-detail-orderList')"
        >
          <order-form-list />
        </el-tab-pane>
        <el-tab-pane
          label="充值列表"
          name="averageUserList-detail-rechargeList"
          v-if="tabList.includes('averageUserList-detail-rechargeList')"
        >
          <recharge-list />
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>
<script>
export default {
  components: {
    vehicleList: () => import("./tabs/vehicleList.vue"),
    orderFormList: () => import("./tabs/orderFormList.vue"),
    rechargeList: () => import("./tabs/rechargeList.vue"),
    editableInput: () => import("@/components/editable-input.vue"),
  },
  data() {
    return {
      tabsName: "averageUserList-detail-vehicleList",
      unionId: "",
      info: {},
    };
  },
  computed: {
    tabList() {
      let list = this.$store.state.controlAuthority.tabList;
      let tabsName_list = [
        "averageUserList-detail-vehicleList",
        "averageUserList-detail-orderList",
      ];
      for (let item of tabsName_list) {
        if (list.includes(item)) {
          this.tabsName = item;
          break;
        }
      }
      return list;
    },
  },
  created() {
    this.unionId = this.$route.params.id;
    this.getInfo();
  },
  methods: {
    async getInfo() {
      try {
        let res = await this.$http.get(`/user/info/${this.unionId}`);
        if (res.code === 0) {
          this.info = res.data;
        } else {
          this.$message.error(res.msg);
        }
      } catch (err) {
        this.$message.error(err);
      }
    },
  },
};
</script>

<style lang="scss">
#averageUserDetail {
  .tab-table {
    padding: 15px;
  }
  .basicsInfo {
    .info {
      .avatarUrl {
        & > div {
          width: 250px;
          .el-image {
            margin: 0 5px 0 16px;
            width: 50px;
            height: 50px;
            border-radius: 50%;
          }
        }
      }
    }
  }
}
</style>
